import { gql } from "@apollo/client";

export const GET_ALL_RECRUITER_DEALS_FOR_RECRUITER = gql`
query GetRecruiterDealsForRecruiter($recruiterId: String!) {
  getRecruiterDealsForRecruiter(recruiterId: $recruiterId) {
    recruiterDeals {
      id
      dealName
      recruiterId
      period
      dealStartDate
      dealEndDate
      recruiterInTraining
      assignedManagerId
      mentorCommission
      trainingCompletionDate
      nonRecoverableDraw
      drawPercentage
      contractingCommission
      biWeeklySalary
      contractingBaseRate
      dhBaseRate
      status
      recruiterDealTiers {
        id
        tierNo
        startRange
        endRange
        commissionPercentage
        tempCommissionPercentage
        recruiterDealId
        tierType
        status
      }
      assignedManager {
        id
        firstName
        lastName
        fullName
      }
      recruiter {
        id
        firstName
        lastName
        fullName
      }
      # recruiterDealHistory {
      #   nonRecoverableDraw
      #   updateMessage
      #   tierUpdated
      #   updatedAt
      # }
    }
  }
}`

export const CREATE_DEAL = gql`
mutation CreateRecruiterDeal($input: CreateRecruiterDealInput!) {
  createRecruiterDeal(createRecruiterDealInput: $input) {
    status
    message
  }
}`

export const UPDATE_DEAL = gql`
mutation UpdateRecruiterDeal($input: UpdateRecruiterDealInput!) {
  updateRecruiterDeal(updateRecruiterDealInput: $input) {
    recruiterDeal {
      id
      dealName
      recruiterId
      period
      dealStartDate
      dealEndDate
      recruiterInTraining
      assignedManagerId
      mentorCommission
      trainingCompletionDate
      nonRecoverableDraw
      drawPercentage
      contractingCommission
      biWeeklySalary
      contractingBaseRate
      dhBaseRate
      createdAt
      updatedAt
      status
      recruiterDealTiers {
        id
        tierNo
        startRange
        endRange
        commissionPercentage
        tempCommissionPercentage
        recruiterDealId
        tierType
      }
      assignedManager {
        id
        firstName
        lastName
        fullName
      }
      recruiter {
        id
        firstName
        lastName
        fullName
      }
      # recruiterDealHistory {
      #   nonRecoverableDraw
      #   updateMessage
      #   tierUpdated
      #   updatedAt
      # }
    }
    status
    message
  }
}`

export const ACTIVATE_DEAL = gql`
mutation ActivateDeal($dealId: String!) {
  activateDeal(dealId: $dealId) {
    status
    message
  }
}`

export const INACTIVATE_DEAL = gql`
mutation InactivateDeal($dealId: String!) {
  inactivateDeal(dealId: $dealId) {
    status
    message
  }
}`

export const DELETE_RECRUITER_DEAL = gql`
mutation DeleteRecruiterDeal($dealId: String!) {
  deleteRecruiterDeal(dealId: $dealId) {
    status
    message
  }
}`

export const REMOVE_RECRUITER_TIER = gql`
mutation RemoveRecruiterDealTier($id: String!) {
  removeRecruiterDealTier(id: $id) {
    status
    message
  }
}`

export const GET_RECRUITER_DEAL_TIER_CHANGE_HISTORY = gql`
query GetRecruiterDealTierHistory ($dealId: String!, $paginationInput: PageCountInput!) {
  getRecruiterDealTierHistory(dealId: $dealId, paginationInput: $paginationInput) {
    count
    recruiterDealHistory {
      id
      tierDeleted
      tierType
      tierUpdated
      tierOverrideType
      updatedAt
      createdAt
    }
    message
    status
  }
}`

export const GET_RECRUITER_DEAL_CHANGE_HISTORY = gql`
query($dealId: String!) {
  getRecruiterDealHistory(dealId: $dealId) {
    recruiterDealHistory {
      id
      tierType
      tierUpdated
      status
      startRange
      endRange
      commissionPercentage
      tierOverrideType
      nonRecoverableDraw
      dealName
      dealStartDate
      dealEndDate
      recruiterInTraining
      assignedManager
      mentorCommission
      trainingCompletionDate
      drawPercentage
      contractingCommission
      biWeeklySalary
      contractingBaseRate
      dhBaseRate
      type
      validDays
      percentage
      note
      updatedAt
      createdAt
    }
    message
    status
  }
}`

export const CREATE_COMMISSION_OVERWRITE = gql`
mutation CreateCommissionOverwrite ($input:CreateCommissionOverwriteInput!){
  createCommission(createCommissionOverwriteInput:$input){
    message
    status
  }
}`

export const UPDATE_COMMISSION_OVERWRITE = gql`
mutation UpdateCommissionOverwrite ($input:UpdateCommissionOverwriteInput!){
  updateCommission(updateCommissionOverwriteInput:$input){
    message
    status
  }
}`

export const DELETE_COMMISSION_OVERWRITE = gql`
mutation DeleteCommissionOverwrite ($id:String!){
  deleteCommission(id:$id){
    message
    status
  }
}`

export const GET_ALL_COMMISSION_OVERWRITE = gql`
query GetAllCommissionOverwrites ($recruiterDealId:String!){
  getAllCommissionOverwrites(recruiterDealId:$recruiterDealId){
    commissionOverwrites{
      id
      type
      validDays
      percentage
      note
      recruiterDealId
    }
    message
    status
  }
}`

export const ACTIVATE_TIER = gql`
mutation ActivateTier($id: String!) {
  activateTier(id: $id) {
    message
    status
  }
}`

export const DEACTIVATE_TIER = gql`
mutation DeactivateTier($id: String!) {
  deactivateTier(id: $id) {
    message
    status
  }
}`