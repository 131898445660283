import { useMutation } from "@apollo/client"
import { FC, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Input } from "reactstrap"
import { CommissionTiersProps } from "../../../../interfaces"
import ConfirmPopUp from "../../../ConfirmPopUp"
import NoDataFound from "../../../layout/NoDataFound"
import TableComponent from "../../../layout/TableComponent"
import { ACTIVATE_TIER, DEACTIVATE_TIER } from "../gql"

const CommissionTiers: FC<CommissionTiersProps> = ({ refetch, tierType, mappingData }) => {
  const [activateTierPopUp, setActivateTierPopUp] = useState<boolean>(false)
  const [activateIndex, setActivateIndex] = useState<number>()
  const [selectedTierNo, setSelectedTierNo] = useState<string>()
  const [activeStatus, setActivateStatus] = useState<boolean[]>([])

  const [deactivateTier, { loading: deactivating }] = useMutation(DEACTIVATE_TIER, {
    onCompleted: () => {
      toast.success(`Tier # ${selectedTierNo} Deactivated successfully`)
      setActivateTierPopUp(false)
      refetch()
    },
    onError: () => toast.error("something went wrong")
  })

  const [activateTier, { loading: activating }] = useMutation(ACTIVATE_TIER, {
    onCompleted: () => {
      toast.success(`Tier # ${selectedTierNo} 'Activated' successfully`)
      setActivateTierPopUp(false)
      refetch()
    },
    onError: () => toast.error("something went wrong")
  })

  useEffect(() => {
    if (mappingData)
      setActivateStatus(mappingData?.map(({ status }) => status === 'active' ? true : false))
  }, [mappingData])

  const handleChangeStatus = (index: number, value: boolean) => setActivateStatus(activeStatus.map((item, i) => {
    if (i === index) {
      console.log("yes")
      return value
    }
    return item
  }))

  return <>
    <div className="fw-700 fs-14 my-4">{`${tierType} Commission Tiers`}</div>

    <TableComponent tableHeader={['Tier #', 'Range', 'Commission', 'Action']}>
      <tbody>
        {mappingData?.length ? mappingData.map(({ tierNo, startRange, endRange, status,
          commissionPercentage }, index) => <tr>
            <td>{tierNo}</td>
            <td style={{ minWidth: 200 }}>{startRange} {endRange === '+' ? '+ ' : ` - ${endRange} `} </td>
            <td>{commissionPercentage.includes('%') ? commissionPercentage : `${commissionPercentage} %`}</td>
            <td>
              <label className="radio-container mx-0" style={{ marginTop: -11 }}>
                <Input
                  type="radio"
                  name={`isActive-${tierType}`}
                  checked={activeStatus[index]}
                  defaultChecked={status === 'active' ? true : false}
                  onChange={({ target: { checked } }) => {
                    handleChangeStatus(index, checked)
                    setSelectedTierNo(tierNo)
                    setActivateIndex(index)
                    setActivateTierPopUp(true)
                  }}
                />
                <span className="checkmark" />
              </label>
            </td>
          </tr>
        )
          :
          <td colSpan={5} className="empty-table-td">
            <NoDataFound text={`No ${tierType} Commission Tier Found!`} />
          </td>}
      </tbody>
    </TableComponent >

    <ConfirmPopUp
      isOpen={activateTierPopUp}
      toggle={() => {
        handleChangeStatus(activateIndex!, !activeStatus[activateIndex!])
        setActivateTierPopUp(!activateTierPopUp)
      }}
      loading={activating || deactivating}
      confirmAction={() => {
        if (activeStatus[activateIndex!])
          activateTier({ variables: { id: mappingData[activateIndex!]?.id } })
        else
          deactivateTier({ variables: { id: mappingData[activateIndex!]?.id } })
      }}
      confirmText={`Are you sure you want to mark Tier ${selectedTierNo} as ${activeStatus[activateIndex!] ? 'activate' : 'deactivated'}?`}
      modalHeading={`${activeStatus[activateIndex!] ? 'Activate' : 'Deactivate'} Tier # ${selectedTierNo}`}
      btnText={`Yes, ${activeStatus[activateIndex!] ? 'Activate' : 'Deactivate'}`}
    />
  </>
}
export default CommissionTiers