import moment from 'moment'
import { FC } from 'react'
import STEPPER_CIRCLE from '../../../../assets/images/circle.svg'
import { DealsHistoryStepperProps } from '../../../../interfaces'
import { getHistoryKey } from '../../../../utils/helper'

const DealsHistoryStepper: FC<DealsHistoryStepperProps> = ({ dealHistory }) => {


  return <div className='fs-14'>
    <div className="d-flex gap-14">
      <img src={STEPPER_CIRCLE} />
      <div className='fw-500'>Updated on: {moment(dealHistory?.updatedAt).format('DD MMMM, YYYY')}</div>
    </div>
    <div className="d-flex"  >
      <div className='stepper-line' />
      <div className='mx-4  fw-300' >
        <div className="d-flex align-items-center my-2" >
          <div className='color-gray' style={{ width: 300 }} >
            {
              Object.entries(dealHistory)
                .filter(([key, value]) => {
                  return key !== 'id' && key !== 'updatedAt' && key !== '__typename' && key !== 'createdAt' && value !== null && value !== ''
                }).map(([key, _]) => <div className='my-2'>{getHistoryKey(key)}</div>)
            }
          </div>

          <div>
            {
              Object.entries(dealHistory)
                .filter(([key, value]) => {
                  return key !== 'id' && key !== 'updatedAt' && key !== '__typename' && key !== 'createdAt' && value !== null && value !== ''
                }).map(([key, value]) => {
                  return <div className='my-2 fw-500'>{value}</div>
                })
            }
          </div>
        </div>
      </div>
    </div>
  </div>
}
export default DealsHistoryStepper
